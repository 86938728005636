import { React, useEffect, useState } from 'react';
import { useStoreState } from 'pullstate';
import { AppStore } from '../../../stores/AppStore';
import MUIDataTable from 'mui-datatables';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    Box,
    IconButton,
    CircularProgress,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';
import { getUserReports, handleReportDelete } from '../../../utils/webUtils';
import { ReportsStore } from '../../../stores/ReportsStore';
import dayjs from 'dayjs';

const MyReports = () => {
    const [loading, setLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState({});
    const [selectedReportId, setSelectedReportId] = useState(null);
    const { reports, userDoc } = useStoreState(AppStore);
    const data = reports;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClick = () => {
        ReportsStore.update((s) => {
            s.currentStep = 1;
        });
    };

    const handleClickOpen = (reportId) => {
        setOpenDialog(true);
        setSelectedReportId(reportId);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleConfirmDelete = () => {
        if (selectedReportId) {
            handleReportDelete(selectedReportId, userDoc, reports);
            handleClose();
        }
    };

    const handleCopyToClipboard = (reportId) => {
        const shareableLink = `${window.location.origin}/report/${reportId}`;
        navigator.clipboard
            .writeText(shareableLink)
            .then(() => {
                setTooltipOpen((prev) => ({ ...prev, [reportId]: true }));
                setTimeout(() => {
                    setTooltipOpen((prev) => ({ ...prev, [reportId]: false }));
                }, 2000);
            })
            .catch((err) => {
                console.error('Failed to copy link: ', err);
            });
    };

    const handleReportLink = (reportId) => {
        const reportLink = `${window.location.origin}/report/${reportId}`;
        navigator.clipboard.writeText(reportLink);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (userDoc.uid) {
                await getUserReports(userDoc.uid);
                setLoading(false);
            }
        };

        fetchData();
    }, [userDoc.uid]);

    const columns = [
        {
            name: 'title',
            label: 'Title',
            options: {
                setCellProps: () => ({ style: { width: '60%' } }),
                customBodyRenderLite: (dataIndex) => {
                    const reportLink = `/report/${data[dataIndex].id}`; // Construct the URL
                    return (
                        <a
                            href={reportLink}
                            onClick={() => handleReportLink(data[dataIndex].id)}
                            style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}
                        >
                            <strong>{data[dataIndex].title}</strong>
                        </a>
                    );
                },
                sort: true,
            },
        },

        {
            name: 'lastUpdated',
            label: 'Date',
            options: {
                setCellProps: () => ({ style: { width: '20%' } }),
                customBodyRenderLite: (dataIndex) => {
                    return dayjs(data[dataIndex].lastUpdated).format('DD/MM/YYYY');
                },
                sort: true,
                // customSort: (a, b) => {
                //     console.log('a', a);
                //     const dateA = dayjs(a.created_at).valueOf();
                //     const dateB = dayjs(b.created_at).valueOf();

                //     return dateA - dateB;
                // },
            },
        },
        {
            name: 'aiFake',
            label: 'AI Score',
            options: {
                setCellProps: () => ({ style: { width: '10%' } }),
                customBodyRenderLite: (dataIndex) => {
                    const scanType = data[dataIndex].fullScanData.scan_results.scan.scan_type;
                    const aiScore = data[dataIndex].fullScanData.scan_results.aiResults?.fake;
                    if (scanType === 0 || scanType === 1) return `${(aiScore * 100).toFixed(2)}%`;
                    return 'NA';
                },
                sort: true,
            },
        },
        {
            name: 'plagiarism',
            label: 'Plagiarism',
            options: {
                setCellProps: () => ({ style: { width: '10%' } }),
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const scanType = data[dataIndex].fullScanData.scan_results.scan.scan_type;
                    if (scanType === 0 || scanType === 2) {
                        return `${data[dataIndex].fullScanData.scan_results.plagResults.documentScore}%`;
                    }
                    return `NA`;
                },
                sort: false,
            },
        },
        {
            name: 'share',
            label: 'Share',
            options: {
                setCellProps: () => ({ style: { width: '5%' } }),
                customBodyRenderLite: (dataIndex) => (
                    <Tooltip
                        title={<h2>Copied to clipboard!</h2>}
                        open={tooltipOpen[data[dataIndex].id] || false}
                        placement='top'
                    >
                        <IconButton
                            onClick={() => {
                                handleCopyToClipboard(data[dataIndex].id);
                            }}
                        >
                            <LinkIcon />
                        </IconButton>
                    </Tooltip>
                ),
                sort: false,
            },
        },
        {
            name: 'delete',
            label: 'Delete',
            options: {
                setCellProps: () => ({ style: { width: '5%' } }),
                customBodyRenderLite: (dataIndex) => (
                    <IconButton onClick={() => handleClickOpen(data[dataIndex].id)}>
                        <DeleteIcon />
                    </IconButton>
                ),
                sort: false,
            },
        },
    ].map((column) => {
        if (isMobile) {
            switch (column.name) {
                case 'lastUpdated':
                case 'aiFake':
                case 'plagiarism':
                    return { ...column, options: { ...column.options, display: 'excluded' } };
                default:
                    return column;
            }
        }
        return column;
    });

    const options = {
        elevation: 0,
        download: false,
        selectableRows: 'none',
        responsive: 'standard',
    };

    if (loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    if (!reports.length) {
        return (
            <Box sx={{ textAlign: 'center', mt: 4 }}>
                <Typography variant='h6' sx={{ marginTop: '3rem' }}>
                    You haven't generated any reports yet! Start here:
                </Typography>
                <Button onClick={handleClick}>Generate Report</Button>
            </Box>
        );
    }

    return (
        <Box sx={{ margin: '2rem' }}>
            <MUIDataTable title={'My Reports'} data={data} columns={columns} options={options} />
            <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>{'Confirm Delete'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        Are you sure you want to delete this report?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleConfirmDelete} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default MyReports;
