import { React, useState } from 'react';
import { Box, Typography, Divider, Chip, Grid, useMediaQuery, useTheme } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { Star, AutoAwesome } from '@mui/icons-material';
import { AppStore } from '../../../stores/AppStore';
import { useStoreState } from 'pullstate';
import { scanOptions, stripePaymentLink } from '../../../utils/webUtils';

const BuyReports = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { user } = useStoreState(AppStore);
    const [loading, setLoading] = useState(false);
    const windowLocationHref = window.location.href;

    const handleCheckout = (priceId) => {
        console.log('priceId', priceId);
        stripePaymentLink(priceId, user, setLoading, windowLocationHref);
    };

    return (
        <Grid container spacing={2} justifyContent='center' sx={{ width: '100%' }}>
            {scanOptions.map((option, index) => (
                <Grid
                    item
                    xs={12}
                    sm={4}
                    key={index}
                    sx={{
                        borderRight:
                            index !== scanOptions.length - 1 && !isMobile
                                ? '2px solid #30BCED'
                                : 'none',
                        paddingBottom: isMobile ? '4rem' : 'none',

                        borderTop: isMobile ? '2px solid #30BCED' : 'none',
                        maxHeight: '100%',
                    }}
                >
                    <Box
                        sx={{
                            textAlign: 'center',
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            padding: '2.5rem',
                            height: '100%',
                        }}
                    >
                        <Box>
                            {option.type === 'Basic' && (
                                <Chip
                                    variant='outlined'
                                    label='Sample'
                                    sx={{
                                        fontSize: '1.25rem',
                                        padding: '1rem',
                                    }}
                                />
                            )}
                            {option.type === 'Most Popular' && (
                                <Chip
                                    icon={<Star color='#fff' />}
                                    label='Most Popular'
                                    sx={{
                                        fontSize: '1.25rem',
                                        padding: '1rem',
                                        backgroundColor: '#fcba04',
                                        color: '#fff',
                                    }}
                                />
                            )}
                            {option.type === 'Best Deal' && (
                                <Chip
                                    icon={<AutoAwesome color='#fff' />}
                                    label='Best Deal'
                                    sx={{
                                        fontSize: '1.25rem',
                                        padding: '1rem',
                                        backgroundColor: '#4FB477',
                                        color: '#fff',
                                    }}
                                />
                            )}
                        </Box>

                        <Typography
                            variant='h5'
                            component='h2'
                            sx={{ fontSize: '2.5rem', fontWeight: 'bold', marginTop: '1.5rem' }}
                        >
                            {option.title}
                        </Typography>
                        <Typography
                            variant='subtitle1'
                            sx={{ fontSize: '1.5rem', minHeight: '10%' }}
                        >
                            {option.subtitle}
                        </Typography>
                        <Typography variant='h3' component='div' sx={{ fontWeight: 'bold' }}>
                            ${option.price}{' '}
                            <Typography variant='h5' component='span'>
                                USD
                            </Typography>
                        </Typography>
                        <Divider sx={{ my: 2, margin: '2rem 1rem' }} />
                        <Typography
                            variant='h5'
                            component='span'
                            sx={{ fontSize: '2rem', textAlign: 'center' }}
                        >
                            {option.incl}
                        </Typography>
                        <Typography
                            variant='h5'
                            component='span'
                            sx={{
                                fontSize: '1.25rem',
                                textAlign: 'center',
                                marginBottom: '2rem',
                            }}
                        >
                            Up to 5,000 words scanned/report
                        </Typography>
                        <LoadingButton
                            onClick={() => handleCheckout(option.priceId)}
                            loading={loading}
                            variant='contained'
                            sx={{
                                backgroundColor:
                                    option.type === 'Most Popular' ? '#30bced' : '#313b77',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: '#17203a',
                                },
                                padding: '0.5rem',
                                fontSize: '1.5rem',
                            }}
                        >
                            Buy {option.reports} Report{option.reports > 1 ? 's' : ''}
                        </LoadingButton>
                    </Box>
                </Grid>
            ))}
        </Grid>
    );
};

export default BuyReports;
