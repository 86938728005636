import {
    AppBar,
    Chip,
    Toolbar,
    Box,
    Button,
    IconButton,
    useMediaQuery,
    useTheme,
    Menu,
    MenuItem,
    ListItemIcon,
} from '@mui/material';
import { useState } from 'react';

import { useStoreState } from 'pullstate';
// import Logo from '../../assets/images/Blue-Inline.png';
import Logo from '../../assets/images/white-inline.svg';
import Shield from '../../assets/images/nobg-shield-whiteoutline.png';
import { AppStore } from '../../stores/AppStore';
import { ReportsStore } from '../../stores/ReportsStore';
import './Navigation.css';
import { resetCurrentScan } from '../../stores/CurrentScan';
import { useNavigate } from 'react-router-dom';
import { logoutWeb } from '../../firebase';
import { Logout, AddCircleOutline, ListAlt, ShoppingBasket, Login } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';

const Navigation = () => {
    const location = window.location.pathname.split('/')[1];
    const { user } = useStoreState(AppStore);
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const buttonStyle = {
        color: 'white',
        '&:hover': {
            textDecoration: 'underline',
            backgroundColor: 'transparent',
        },
        marginRight: '2rem',
        textTransform: 'capitalize',
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMyResultsClick = () => {
        ReportsStore.update((s) => {
            s.currentStep = 5;
        });
        navigate('/report/');
        handleMenuClose();
    };
    const handleBuyReportsClick = () => {
        ReportsStore.update((s) => {
            s.currentStep = 3;
        });
        navigate('/report/');
        handleMenuClose();
    };
    const handleNewReportClick = () => {
        navigate('/report/');
        resetCurrentScan();
        handleMenuClose();
    };

    const handleLoginClick = () => {
        ReportsStore.update((s) => {
            s.currentStep = 2;
        });
        navigate('/report/');
        handleMenuClose();
    };

    const logoutAndCloseMenu = () => {
        logoutWeb();
        handleMenuClose(); // Close the menu
    };

    const renderConditionalButton = (condition, onClick, text) =>
        condition && (
            <Button size='large' onClick={onClick} variant='text' sx={buttonStyle}>
                {text}
            </Button>
        );

    return (
        !(location === 'ext-scan' || location === 'ext-scans') && (
            <AppBar position='static' sx={{ backgroundColor: '#1f2641' }}>
                <Toolbar
                    sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            src={isMobile ? Shield : Logo}
                            alt='Passed.AI Logo'
                            className='header-logo'
                            style={{ width: isMobile ? 35 : 200, height: 'auto' }}
                        />
                        <Chip label='Reports' size='small' color='secondary' />
                    </Box>
                    {isMobile ? (
                        <div>
                            <IconButton
                                size='large'
                                edge='end'
                                color='inherit'
                                aria-label='menu'
                                onClick={handleMenuClick}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id='menu-appbar'
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={handleMenuClose}
                            >
                                {user && (
                                    <MenuItem onClick={handleNewReportClick}>
                                        <ListItemIcon>
                                            <AddCircleOutline fontSize='small' />
                                        </ListItemIcon>
                                        New Report
                                    </MenuItem>
                                )}
                                {user && (
                                    <MenuItem onClick={handleMyResultsClick}>
                                        <ListItemIcon>
                                            <ListAlt fontSize='small' />
                                        </ListItemIcon>
                                        My Reports
                                    </MenuItem>
                                )}
                                {user && (
                                    <MenuItem onClick={handleBuyReportsClick}>
                                        <ListItemIcon>
                                            <ShoppingBasket fontSize='small' />
                                        </ListItemIcon>
                                        Buy Reports
                                    </MenuItem>
                                )}
                                {!user && (
                                    <MenuItem onClick={handleLoginClick}>
                                        <ListItemIcon>
                                            <Login fontSize='small' />
                                        </ListItemIcon>
                                        Login
                                    </MenuItem>
                                )}
                                {user && (
                                    <MenuItem onClick={logoutAndCloseMenu}>
                                        <ListItemIcon>
                                            <Logout fontSize='small' />
                                        </ListItemIcon>
                                        Logout
                                    </MenuItem>
                                )}
                            </Menu>
                        </div>
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {renderConditionalButton(user, handleNewReportClick, 'New Report')}
                            {renderConditionalButton(user, handleMyResultsClick, 'My Reports')}
                            {renderConditionalButton(user, handleBuyReportsClick, 'Buy Reports')}
                            {renderConditionalButton(!user, handleLoginClick, 'Login')}
                            {user && (
                                <IconButton
                                    size='large'
                                    onClick={() => logoutWeb()}
                                    sx={{ color: '#fff' }}
                                >
                                    <Logout />
                                </IconButton>
                            )}
                        </Box>
                    )}
                </Toolbar>
            </AppBar>
        )
    );
};

export default Navigation;
