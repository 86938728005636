import { Store } from 'pullstate';
import { ReportsStore } from './ReportsStore';

const initialCurrentScanState = {
    content: '',
    title: '',
    words: [],
    wordCount: 0,
    credits: 0,
    aiCheck: true,
    plagiarismCheck: false,
    results: null,
    scanDisabled: false,
    inputDisabled: false,
    scanType: null,
    error: false,
    activeTab: 'results',
    loading: false,
    loadingDrop: false,
    mode: 'ai',
    highlightOn: false,
    model: 1,
    report: {},
};

export const CurrentScan = new Store(initialCurrentScanState);

export const resetCurrentScan = () => {
    ReportsStore.update((s) => {
        s.currentStep = 1;
    });
    CurrentScan.update(() => initialCurrentScanState);
};
