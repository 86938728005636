// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

// const firebaseConfig = {
//     apiKey: 'AIzaSyBRPeKaR4mYp0Kf7jgXmR5sA849aJxpY6E',
//     authDomain: 'passedai-development.firebaseapp.com',
//     projectId: 'passedai-development',
//     storageBucket: 'passedai-development.appspot.com',
//     messagingSenderId: '90658115733',
//     appId: '1:90658115733:web:0e565972d8c9710ee94b9c',
//     measurementId: 'G-C64PC2B7MS',
// };
const firebaseConfig = {
    apiKey: 'AIzaSyDi8w-vyq0ZNi6SPZmZyfi_LZ8PJSaUTs8',
    authDomain: 'passedai.firebaseapp.com',
    projectId: 'passedai',
    storageBucket: 'passedai.appspot.com',
    messagingSenderId: '1074435050373',
    appId: '1:1074435050373:web:c4b087b7c8c04bfbd392ec',
    measurementId: 'G-4JR2CC9NN8',
};

const app = initializeApp(firebaseConfig);
const provider = new GoogleAuthProvider();
provider.setCustomParameters({
    prompt: 'select_account ',
});
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
export const auth = getAuth(app);
export const logout = () => {
    // GTM
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'sign_out',
    });
    window.location.href = '/';
    auth.signOut();
};

export const logoutWeb = () => {
    // GTM
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'sign_out',
    });
    window.location.href = '/report';
    auth.signOut();
};
export const callHttpsFunction = (functionName, data) => {
    const func = httpsCallable(functions, functionName);
    return func(data);
};
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);
export const createNewUser = httpsCallable(functions, 'createNewUser');
export const retrieveAssignment = httpsCallable(functions, 'retrieveAssignment');

export default app;
