import ScanInput from './ScanInput';
import Grid from '@mui/material/Unstable_Grid2';
import ScanOptions from './ScanOptions';
import { useStoreState } from 'pullstate';
import { CurrentScan } from '../../../stores/CurrentScan';
import { useEffect } from 'react';
import { calcReports, calcCredits } from '../../../utils/webUtils';
import './Scanner.scss';
import UploadDoc from './UploadDoc';
import { Alert, LinearProgress } from '@mui/material';
import { AppStore } from '../../../stores/AppStore';

const Scanner = () => {
    const { wordCount, aiCheck, plagiarismCheck, content, loading } = useStoreState(CurrentScan);
    const { user } = useStoreState(AppStore);

    useEffect(() => {
        CurrentScan.update((s) => {
            s.reportCost = calcReports({ wordCount });
            s.creditCost = calcCredits({ wordCount, aiCheck, plagiarismCheck });
        });
    }, [wordCount, aiCheck, plagiarismCheck]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const session_id = urlParams.get('session_id');
        if (session_id && user) {
            // Assuming 'user' is defined and available in this context
            const userId = user.uid;
            const productId = 'prod_POQNi5ENVyzUCn';

            fetch('https://us-central1-passedai.cloudfunctions.net/updateUserScansOnPayment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    sessionId: session_id,
                    userId: userId,
                    productId: productId,
                }),
            }).then((response) => response.json());
        }
    }, [user, user?.uid]);

    return (
        <Grid sx={{ width: '100%' }}>
            {/* <ScanHeader /> */}

            {!content && <UploadDoc />}
            {content && <ScanOptions />}
            {loading && <LinearProgress color='secondary' />}
            {!loading && <div style={{ height: '4px' }} />}

            {wordCount > 5000 && (
                <Alert severity='warning' sx={{ marginTop: 1, marginBottom: 1 }}>
                    One report covers up to 5000 words. If your text is longer, we'll need to charge
                    for two reports.
                </Alert>
            )}
            <ScanInput />
        </Grid>
    );
};

export default Scanner;
