import React, { useCallback } from 'react';
import { ref, uploadBytesResumable } from 'firebase/storage';
import { httpsCallable } from 'firebase/functions'; // assuming you've set up Firebase in this file
import { functions, storage } from '../../../firebase';
import { useDropzone } from 'react-dropzone';
import { CurrentScan } from '../../../stores/CurrentScan';
import { useStoreState } from 'pullstate';
import { CircularProgress, Divider } from '@mui/material';
import { gDocWordCount } from '../../../utils/webUtils';
import './UploadDoc.scss';
import { AppStore } from '../../../stores/AppStore';

function UploadDoc() {
    const { loadingDrop } = useStoreState(CurrentScan);
    const { user } = useStoreState(AppStore);
    const maxSize = 10485760; // 10MB in bytes
    const onDrop = useCallback((acceptedFiles, fileRejections) => {
        if (fileRejections.length > 0) {
            alert(
                'This file was not accepted. Please make sure it is a .doc, .docx, or .pdf. The maximum file size is 10MB.'
            );
        } else if (!user) {
            alert('You must be logged in to upload files.');
            AppStore.update((s) => {
                s.shouldBounceLogin = true;
            });
            CurrentScan.update((s) => {
                s.loadingDrop = false;
            });
            return;
        } else {
            handleUpload(acceptedFiles[0]);
        }
    }, [user]);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
                '.doc',
                '.docx',
            ],
            'application/pdf': ['.pdf'],
        },
        maxSize,
        className: 'drop-zone active',
    });

    const handleUpload = async (file) => {
        // console.log(`userDoc: ${userDoc}`);
        CurrentScan.update((s) => {
            s.loadingDrop = true;
        });
        let type = file.type;
        if (!type) {
            const extension = file.name.split('.').pop().toLowerCase();
            if (extension === 'icloud') {
                console.error('Please download the file from iCloud before uploading.');
                alert('Please download the file from iCloud before uploading.');
                return;
            }
            const extensionToMime = {
                doc: 'application/msword',
                docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                pdf: 'application/pdf',
            };
            type = extensionToMime[extension];
        }
        // If type is still undefined, the file extension was not recognized
        if (!type) {
            alert('Unrecognized file extension');
            CurrentScan.update((s) => {
                s.loadingDrop = true;
            });
            return;
        }
        // console.log(file);
        const storageRef = ref(storage, file.name);
        const uploadTask = uploadBytesResumable(storageRef, file);
        try {
            // console.log(type);
            await uploadTask;
            const processFile = httpsCallable(functions, 'processFile');
            const result = await processFile({
                path: file.name,
                contentType: type,
            });
            // console.log(result.data.text);
            CurrentScan.update((s) => {
                s.content = result.data.text;
                s.title = file.name.split('.')[0];
                s.loadingDrop = false;
                s.words = result.data.text.split(' ');
                s.wordCount = gDocWordCount(result.data.text);
            });
        } catch (error) {
            alert(
                'Error during upload or processing: Please make sure your file type is .doc, .docx, or .pdf'
            );
            CurrentScan.update((s) => {
                s.loadingDrop = false;
            });
        }
    };

    return (
        <>
            <div {...getRootProps()} className={`drop-zone ${isDragActive ? 'active' : ''}`}>
                <input {...getInputProps()} />
                {isDragActive ? (
                    <p>Drop the files here ...</p>
                ) : loadingDrop ? (
                    <CircularProgress />
                ) : (
                    <>
                        <p>Drag & drop .doc, .docx or .pdf files here, or click to select file</p>
                        <small>(10mb max file size)</small>
                    </>
                )}
            </div>
            <Divider>or</Divider>
            <div style={{ marginBottom: 16 }} />
        </>
    );
}

export default UploadDoc;
