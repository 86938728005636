import {
    getAuth,
    isSignInWithEmailLink,
    onAuthStateChanged,
    signInWithEmailLink,
} from 'firebase/auth';
// import { useStoreState } from 'pullstate';
import { useEffect, useState } from 'react';
import { AppStore } from './stores/AppStore';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { getSetUserDoc, getSubCollectionData } from './utils/getData';
import { createTheme, ThemeProvider } from '@mui/material';
import { LMSStore } from './stores/LMSStore';
import { auth } from './firebase';
import TagManager from 'react-gtm-module';
// import Login from './Pages/Login/Login';
import PrivateRoutes from './utils/PrivateRoutes';
import Navigation from './components/Navigation/Navigation';
// import useMediaQuery from '@mui/material/useMediaQuery';
import MainReport from './components/MainReport/MainReport';

import Loading from './components/Loading/Loading';
import Report from './Pages/Report/Report';
import SharedReport from './Pages/Report/SharedReport/SharedReport';
import Login from './Pages/Login/Login';
import './App.css';

// GTM
const tagManagerArgs = {
    gtmId: 'GTM-M94H4PS',
};

TagManager.initialize(tagManagerArgs);
window.document.title = 'Passed.AI for Students';

window.dataLayer.push({
    event: 'pageview',
});

function App() {
    const [user, setUser] = useState(null);
    const [ready, setReady] = useState(false);

    //GTM

    //create mui theme
    const passedTheme = createTheme({
        palette: {
            primary: {
                main: '#1f2641',
            },
            secondary: {
                main: '#FFC107',
            },
            tertiary: {
                main: '#e55934',
            },
            quaternary: {
                main: '#30BCED',
            },
            success: {
                main: '#4FB477',
                contrastText: '#fff',
            },
            danger: {
                main: '#D32F2F',
                contrastText: '#fff',
            },
        },
    });

    useEffect(() => {
        onAuthStateChanged(getAuth(), async (fbUser) => {
            if (fbUser) {
                setUser(fbUser);
                AppStore.update((s) => {
                    s.user = fbUser;
                });
                getSetUserDoc(fbUser);
                getSubCollectionData(fbUser.uid, 'assignments', LMSStore);

                //small delay just to show the loading screen
                //can be removed if we don't want to show the loading screen
                setTimeout(() => {
                    setReady(true);
                }, 300);
            }
            //small delay just to show the loading screen
            //can be removed if we don't want to show the loading screen
            setTimeout(() => {
                setReady(true);
            }, 300);
        });
    }, [user]);

    useEffect(() => {
        if (isSignInWithEmailLink(auth, window.location.href)) {
            // Additional state parameters can also be passed via URL.
            // This can be used to continue the user's intended action before triggering
            // the sign-in operation.
            // Get the email if available. This should be available if the user completes
            // the flow on the same device where they started it.
            let email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                // User opened the link on a different device. To prevent session fixation
                // attacks, ask the user to provide the associated email again. For example:
                email = window.prompt('Please provide your email for confirmation');
            }
            // The client SDK will parse the code from the link for you.
            signInWithEmailLink(auth, email, window.location.href)
                .then(() => {
                    // Clear email from storage.
                    window.localStorage.removeItem('emailForSignIn');
                })
                .catch((error) => {
                    // Some error occurred, you can inspect the code: error.code
                    // Common errors could be invalid email and invalid or expired OTPs.
                    console.error('error', error);
                });
        }
    }, []);

    if (!ready) {
        return <Loading />;
    } else if (!user) {
        return (
            <ThemeProvider theme={passedTheme}>
                <Router>
                    <Routes>
                        <Route path='*' element={<Login />} />
                        <Route path='*' element={<Navigate to='/login' replace />} />
                    </Routes>
                </Router>
            </ThemeProvider>
        );
    } else if (user) {
        return (
            <>
                <ThemeProvider theme={passedTheme}>
                    <Router>
                        {/* {user && <Navigation />} */}
                        {/* commented out for reports */}
                        <Navigation />

                        <div
                            className='app_content'
                            //conditionals in style are to accomodate login page
                            style={{
                                maxWidth: '100vw',
                                maxHeight: '100vh',
                            }}
                        >
                            <Routes>
                                <Route element={<PrivateRoutes user={user} />}>
                                    <Route path='/' element={<MainReport content={<Report />} />} />
                                    <Route
                                        path='/login'
                                        element={<Navigate to='/report' replace />}
                                    />
                                    <Route
                                        path='/report'
                                        element={<MainReport content={<Report />} />}
                                    />
                                </Route>
                                <Route
                                    path='/report/:id'
                                    element={<MainReport content={<SharedReport />} />}
                                />
                            </Routes>
                        </div>
                    </Router>
                </ThemeProvider>
            </>
        );
    }
}

export default App;
