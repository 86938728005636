import React from 'react';
import companyLogo from './inline-nobg-shield-bluename.png';
import originalityEfficay from './originality-efficacy.jpg';
import aiGeneratedReviews from './ai-generated-reviews.jpg';
import shield from './nobg-shield.png';
import { Page, Text, Document, StyleSheet, Image, View, Link, Font } from '@react-pdf/renderer';

Font.register({
    family: 'Roboto',
    fonts: [
        // Register the regular font weight
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
        // Register the bold font weight
        {
            src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
            fontWeight: 600,
        },
    ],
});

const styles = StyleSheet.create({
    body: {
        paddingTop: 80,
        paddingBottom: 80,
        paddingHorizontal: 80,
    },
    title: {
        fontFamily: 'Roboto',
        fontSize: 20,
        fontWeight: 700,
        textAlign: 'center',
        marginBottom: 10,
        // fontWeight: 'bold',
        color: '#1F2641',
    },
    subtitle: {
        fontFamily: 'Roboto',
        fontWeight: 600,
        fontSize: 16,
        marginTop: 20,
        marginBottom: 10,
        // fontWeight: 'bold',
        color: '#1F2641',
    },
    text: {
        fontFamily: 'Roboto',
        marginVertical: 5,
        fontSize: 12,
        textAlign: 'justify',
        lineHeight: 1.15,
    },
    boldText: {
        fontFamily: 'Roboto',
        fontWeight: 600,
        // fontWeight: 'bold',
    },
    image: {
        marginVertical: 15,
    },
    companyImage: {
        // height: 90,
        height: 70,
        width: 'auto',
        marginVertical: 15,
        marginHorizontal: 'auto',
    },
    header: {
        fontFamily: 'Roboto',
        fontSize: 16,
        marginBottom: 10,
        fontWeight: 600,
        color: '#1F2641',
    },
    headerLogo: {
        width: 18, // Adjust the width as needed
        height: 'auto', // Adjust the height as needed
        position: 'absolute',
        top: 15, // Adjust the top position as needed
        right: 15, // Adjust the right position as needed
    },
    divider: {
        marginVertical: 10,
    },
    link: {
        color: 'blue',
    },
    verificationLink: {
        fontSize: 12,
        marginBottom: 80,
        textAlign: 'center',
    },
    timeStamp: {
        fontSize: 12,
        textAlign: 'center',
    },
    scoreSection: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 5,
        marginBottom: 5,
        // paddingHorizontal: 20,
    },
    scoreText: {
        fontFamily: 'Roboto',
        width: 60,
        fontSize: 16,
        marginRight: 10,
    },
    progressBarContainer: {
        flex: 1,
        height: 20,
        borderRadius: 10,
        position: 'relative',
        backgroundColor: '#eee',
        flexDirection: 'row',
        alignItems: 'center',
    },
    progressBarBackground: {
        backgroundColor: '#eee',
        height: '100%',
        borderRadius: 10,
    },
    progressBar: {
        height: '100%',
        borderRadius: 10,
    },
    pageNumber: {
        fontFamily: 'Roboto',
        position: 'absolute',
        fontSize: 12,
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#cccccc',
        alignItems: 'center',
        height: 24,
        textAlign: 'left',
    },
    tableCell: {
        fontSize: 10,
        padding: 2,
    },
    tableHeader: {
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        backgroundColor: '#eeeeee',
    },
    matchedPhrase: {
        width: '50%', // Adjust the width as needed
    },
    referenceLink: {
        color: 'blue',
        width: '30%', // Adjust the width as needed
    },
    appendixHeader: {
        fontSize: 20,
        textAlign: 'center',
        margin: 10,
    },
    referenceItem: {
        marginBottom: 5,
        paddingHorizontal: 20,
    },
    referenceText: {
        fontFamily: 'Roboto',
        fontSize: 12,
    },
});

const getReportTitle = (scanType) => {
    switch (scanType) {
        case 0:
            return 'Complete AI and Plagiarism Detection Report';
        case 1:
            return 'Complete AI Detection Report';
        case 2:
            return 'Complete Plagiarism Detection Report';
        default:
            return 'Complete AI and Plagiarism Insights Report';
    }
};

const ProgressBar = ({ score, color }) => {
    const scoreWithTwoDecimals = score.toFixed(2);

    return (
        <View style={styles.progressBarContainer}>
            <View
                style={{ height: '100%', width: '100%', backgroundColor: '#eee', borderRadius: 10 }}
            />
            {scoreWithTwoDecimals > 0 && (
                <View
                    style={{
                        height: '100%',
                        width: `${score}%`,
                        backgroundColor: color,
                        borderRadius: 10,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    }}
                />
            )}
        </View>
    );
};

const getProgressBarColour = (score) => {
    if (score > 80) return 'red';
    if (score > 30) return 'orange';
    return 'green';
};

const ReportPdfDocument = ({
    reportData,
    formattedDataAndTime,
    aiFakeScore,
    aiFakeScoreExplanation,
    aiRealScore,
    aiRealScoreExplanation,
    plagiarismScore,
    plagiarismExplanation,
    scanType,
    verificationLink,
}) => (
    <Document>
        <Page style={styles.body}>
            <Image style={styles.companyImage} src={companyLogo} />

            <Text style={styles.title}>{getReportTitle(scanType)}</Text>
            <Text style={styles.timeStamp}>Scanned on {formattedDataAndTime}</Text>
            {verificationLink && (
                <Text style={styles.verificationLink}>
                    Verify this report online at <Link href={verificationLink}>Passed.ai</Link>
                </Text>
            )}
            <View style={styles.divider} />
            {(scanType === 0 || scanType === 1) && (
                <>
                    <Text style={styles.header}>AI Score</Text>
                    <View style={styles.scoreSection}>
                        <Text style={styles.scoreText}>{(aiFakeScore * 100).toFixed(2)}%</Text>
                        <ProgressBar
                            key='aiScore'
                            score={aiFakeScore * 100}
                            color={getProgressBarColour(aiFakeScore * 100)}
                        />
                    </View>
                    <Text style={styles.text}>{aiFakeScoreExplanation}</Text>
                </>
            )}
            <View style={styles.divider} />
            {(scanType === 0 || scanType === 2) && (
                <>
                    <Text style={styles.header}>Plagiarism Score</Text>
                    <View style={styles.scoreSection}>
                        <Text style={styles.scoreText}>{plagiarismScore.toFixed(2)}%</Text>
                        <ProgressBar
                            key='plagiarismScore'
                            score={plagiarismScore}
                            color={getProgressBarColour(plagiarismScore)}
                        />
                    </View>
                    <Text style={styles.text}>{plagiarismExplanation}</Text>
                    <Text style={styles.text}>
                        For detailed information on each instance of potential plagiarism, please
                        refer to Appendix A at the end of this document.
                    </Text>
                </>
            )}
            <View style={styles.divider} />
            {/* <Text style={styles.pageNumber} render={({ pageNumber }) => `${pageNumber}`} fixed /> */}
        </Page>
        <Page style={styles.body}>
            <Image style={styles.headerLogo} src={shield} />
            <Text style={styles.header}>How to Interpret the Results</Text>
            <Text style={styles.text}>
                The report provides insights into the document's content, highlighting the
                likelihood of AI involvement and potential plagiarism issues. A higher AI score
                suggests greater AI influence, while the plagiarism score indicates the extent of
                content matching with external sources.
            </Text>
            <Text style={styles.text}>
                <Text style={styles.boldText}>AI Confidence Interpretation:</Text> A higher AI score
                indicates a higher likelihood of AI-generated content. The report assesses writing
                patterns and stylistic elements to determine AI involvement.
            </Text>
            <Text style={styles.text}>
                <Text style={styles.boldText}>Plagiarism Score Interpretation:</Text> The Plagiarism
                Score reveals any potential plagiarism issues, with a lower score indicating minimal
                or no plagiarism and a higher score suggesting significant plagiarized content.
            </Text>
            <View style={styles.divider} />
            <Text style={styles.header}>Overview of Our Advanced Document Analysis Tool</Text>
            <Text style={styles.text}>
                In today's fast-evolving academic landscape, our document analysis tool emerges as a
                pioneering solution, diligently crafted to safeguard the sanctity of academic work.
                This advanced software is engineered to meticulously identify AI-generated content
                and plagiarism, employing state-of-the-art algorithms and machine learning
                techniques. These methods are fine-tuned to discern the subtle differences between
                content created by humans and that generated by AI.
            </Text>
            <View style={styles.divider} />

            <Text style={styles.pageNumber} render={({ pageNumber }) => `${pageNumber}`} fixed />
        </Page>
        <Page style={styles.body}>
            <Image style={styles.headerLogo} src={shield} />
            <Text style={styles.header}>Deep Dive into the Analysis Process</Text>
            <Text style={styles.text}>
                The core of our tool's effectiveness lies in its sophisticated analysis algorithms.
                Utilizing a combination of natural language processing (NLP) and advanced pattern
                recognition, it meticulously examines each document. This includes an analysis of
                sentence structure, word choice, and the overall flow of the text.{' '}
                <Link
                    style={styles.link}
                    href='https://originality.ai/blog/ai-content-detection-accuracy'
                >
                    A pivotal study by Originality.AI
                </Link>{' '}
                plays a crucial role in this process, where it was found that certain linguistic
                patterns are more prevalent in AI-generated text. For example, AI-written content
                often exhibits a lack of nuanced human emotion and may overuse certain phrases or
                syntactical structures. Our tool is calibrated to detect these nuances,
                distinguishing AI-generated content with a high degree of accuracy.
            </Text>
            <View style={styles.divider} />
            <Image style={styles.image} src={originalityEfficay} />
            <View style={styles.divider} />

            <Text style={styles.header}>Metrics and Studies</Text>
            <Text style={styles.text}>
                The reliability of our technology is underscored by rigorous testing and research.
                In a comprehensive study, the technology was tested across various content types and
                sources. One key metric of success was the tool's ability to accurately identify
                AI-generated text. The study revealed an impressive accuracy rate, significantly
                higher than traditional plagiarism detection software. This accuracy is pivotal in
                academic settings, where discerning between original student work and AI-assisted
                content is essential.
            </Text>
            <Text style={styles.pageNumber} render={({ pageNumber }) => `${pageNumber}`} fixed />
        </Page>
        <Page style={styles.body}>
            <Image style={styles.headerLogo} src={shield} />
            <Text style={styles.header}>Case Studies and Real-World Application</Text>
            <Text style={styles.text}>
                The real-world applicability of our tool is best illustrated through specific case
                studies. For instance, in a controlled academic environment, the tool was used to
                analyze a set of essays, some written by students and others generated by advanced
                AI writing tools. The results were striking – our tool successfully identified the
                AI-generated essays with a remarkable accuracy rate, as indicated by the study. This
                level of precision is invaluable for educators who strive to uphold the authenticity
                of student submissions.
            </Text>
            <Text style={styles.text}>
                In an era where Large Language Models (LLMs) like GPT-3.5 are becoming increasingly
                sophisticated, the trustworthiness of AI text detectors has come under scrutiny.
                Recent studies, including a pivotal one titled{' '}
                <Link style={styles.link} href='https://arxiv.org/pdf/2305.10847.pdf'>
                    "Large Language Models can be Guided to Evade AI-Generated Text Detection"
                </Link>
                , have highlighted the evolving capabilities of LLMs to bypass detection mechanisms.
            </Text>
            <Text style={styles.text}>
                This study reveals that LLMs, when equipped with specific prompts or optimization
                methods like the Substitution-based In-Context example Optimization (SICO), can
                effectively evade various detectors. These findings underscore the need for
                continuous advancement in detection technology. Our tool, aware of these
                advancements, is designed to adapt and evolve, incorporating strategies to
                counteract these sophisticated evasion techniques.
            </Text>
            <Text style={styles.text}>
                The effectiveness of AI text detectors, as the study suggests, should not solely be
                based on their ability to identify AI-generated content under standard conditions.
                Instead, their resilience against advanced evasion tactics like those demonstrated
                by SICO is equally important. In light of this, our tool undergoes rigorous testing,
                not just against typical AI-generated content, but also against content crafted to
                evade detection. This comprehensive approach ensures a more reliable and trustworthy
                detection system.
            </Text>
            <Text style={styles.text}>
                While these developments might seem daunting, they serve as a catalyst for continual
                improvement in our document analysis tool. The study's findings are integrated into
                our development process, ensuring that our tool remains at the forefront of
                detection technology. We strive to maintain a balance between trust in our tool's
                current capabilities and the drive to enhance its effectiveness in line with
                emerging challenges.
            </Text>
            <Text style={styles.text}>
                Trust in AI text detectors is pivotal, especially in academic settings where the
                integrity of written work is paramount. Our commitment to advancing our tool in
                response to the latest research and techniques ensures that it remains a reliable
                resource in the ever-evolving landscape of AI writing and detection. The goal is not
                just to keep pace with advancements but to anticipate and prepare for future
                challenges in AI-generated text detection.
            </Text>
            <Text style={styles.pageNumber} render={({ pageNumber }) => `${pageNumber}`} fixed />
        </Page>
        <Page style={styles.body}>
            <Image style={styles.headerLogo} src={shield} />
            <Text style={styles.header}>Why the Distinction Matters</Text>
            <Text style={styles.text}>
                In the realm of education, the integrity of academic work is a cornerstone. Our tool
                not only reinforces this integrity but also equips educators and students with the
                knowledge to navigate the emerging challenges posed by AI-written content.
                Understanding and being able to identify AI-generated material is crucial,
                especially as AI writing tools become more accessible and sophisticated.
            </Text>
            <Image style={styles.image} src={aiGeneratedReviews} />
            <View style={styles.divider} />

            <Text style={styles.header}>Enhancing Academic Integrity</Text>
            <Text style={styles.text}>
                While the primary function of our tool is to detect AI-generated content, its scope
                extends beyond just identification. It serves as an educational asset, providing
                insights into the nature of AI writing. For example, the study highlighted that AI
                writing tends to have a specific tone and style, which, while sophisticated, lacks
                the depth and variability of human writing. Our tool helps educators and students
                understand these nuances, fostering a deeper appreciation of authentic human
                creativity and expression.
            </Text>

            <Text style={styles.pageNumber} render={({ pageNumber }) => `${pageNumber}`} fixed />
        </Page>
        <Page style={styles.body}>
            <Image style={styles.headerLogo} src={shield} />
            <Text style={styles.header}>Advancing with Evolving AI</Text>
            <Text style={styles.text}>
                As AI technology continues to evolve, so does our document analysis tool. The study
                underscored the importance of continual learning and adaptation in the face of
                rapidly advancing AI writing capabilities. Our tool incorporates this principle,
                regularly updating its algorithms to stay ahead of the latest AI writing trends and
                patterns. This ensures that the tool remains effective and relevant, providing users
                with a reliable resource for detecting AI-generated content.
            </Text>
            <View style={styles.divider} />

            <Text style={styles.header}>Conclusion and Forward Outlook</Text>
            <Text style={styles.text}>
                Our document analysis tool stands at the intersection of technological innovation
                and academic integrity, offering a robust solution against the backdrop of AI
                advancements. It not only distinguishes between human and AI-generated content but
                also represents a commitment to preserving the authenticity of academic work. As the
                digital landscape evolves, so will our tool, adapting to new challenges and
                continuously improving in response to the needs of the academic community. Our aim
                is to ensure that it remains a vital resource in upholding the principles of
                originality and integrity in education. In summary, this tool is a testament to our
                dedication to the core values of education. It serves not just as a technological
                asset but as a beacon for ethical engagement with digital content, empowering users
                to navigate the complexities of the digital age with confidence and integrity.
            </Text>
            <View style={styles.divider} />
            <Text style={styles.header}>Disclaimer</Text>
            <Text style={styles.text}>
                This report is intended for academic purposes to assist students and educators in
                assessing the authenticity and originality of written work. While we strive for
                accuracy, the results provided have a degree of uncertainty and should be
                interpreted within the context of a broader assessment strategy.
            </Text>
            <Text style={styles.pageNumber} render={({ pageNumber }) => `${pageNumber}`} fixed />
        </Page>
        {(scanType === 0 || scanType === 2) && (
            <Page style={styles.body}>
                <Image style={styles.headerLogo} src={shield} />
                <Text style={styles.appendixHeader}>
                    Appendix A - Plagiarized Content References
                </Text>
                <View style={[styles.tableRow, styles.tableHeader]}>
                    <Text style={{ ...styles.tableCell, width: '80%' }}>Plagiarized Phrase</Text>
                    <Text style={{ ...styles.tableCell, width: '20%' }}>Average Score</Text>
                </View>
                {reportData.scan_results.plagResults.phraseResults.map((phrase, index) => {
                    const averageScore =
                        phrase.matches.reduce((acc, match) => acc + match.score, 0) /
                        phrase.matches.length;
                    return (
                        <View key={index}>
                            <View style={styles.tableRow}>
                                <Text style={{ ...styles.tableCell, width: '80%' }}>
                                    {phrase.phrase}
                                </Text>
                                <Text style={{ ...styles.tableCell, width: '20%' }}>
                                    {averageScore ? averageScore.toFixed(2) : '0.00'}%
                                </Text>
                            </View>
                            {phrase.matches.map((match, matchIndex) => (
                                <View key={`${index}-${matchIndex}`} style={styles.tableRow}>
                                    <Text style={{ ...styles.tableCell, width: '100%' }}>
                                        Source {matchIndex + 1}:{' '}
                                        <Link style={styles.link} href={match.website}>
                                            {match.website}
                                        </Link>
                                    </Text>
                                </View>
                            ))}
                        </View>
                    );
                })}
                <Text
                    style={styles.pageNumber}
                    render={({ pageNumber }) => `${pageNumber}`}
                    fixed
                />
            </Page>
        )}
    </Document>
);

export default ReportPdfDocument;
