import React, { useEffect } from 'react';
import { Card, CardContent, Typography, Box, Button } from '@mui/material';
import { useStoreState } from 'pullstate';
import { AppStore } from '../../../stores/AppStore';
import { CurrentScan } from '../../../stores/CurrentScan';
import { ReportsStore } from '../../../stores/ReportsStore';
import 'animate.css';

const ReportCard = () => {
    const { userDoc, shouldBounceLogin } = useStoreState(AppStore);
    const { reportCost } = useStoreState(CurrentScan);

    const getButtonText = () => {
        if (!userDoc.uid) {
            return 'Log in to generate a report';
        } else if (userDoc.reports < reportCost || !userDoc.reports) {
            return 'Buy reports to continue';
        }
    };

    const onClick = () => {
        if (!userDoc.uid) {
            ReportsStore.update((s) => {
                s.currentStep = 2;
            });
        } else if (!userDoc.reports || userDoc.reports < reportCost) {
            ReportsStore.update((s) => {
                s.currentStep = 3;
            });
        }
    };

    useEffect(() => {
        if (shouldBounceLogin) {
            const timer = setTimeout(() => {
                AppStore.update((s) => {
                    s.shouldBounceLogin = false;
                });
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [shouldBounceLogin]);

    return (
        <Card sx={{ position: 'relative', boxShadow: 'none', width: '100%' }}>
            <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
                {' '}
                {(!userDoc.uid || !userDoc.reports) && (
                    <Button
                        variant='contained'
                        size='large'
                        onClick={onClick}
                        className={shouldBounceLogin ? 'animate__animated animate__bounce' : ''}
                    >
                        {getButtonText()}
                    </Button>
                )}
            </Box>
            <CardContent>
                <Typography variant='h4' gutterBottom>
                    Generate AI and/or plagiarism reports!
                </Typography>
                <Typography variant='body1'>
                    Simply upload or paste your document, and get a detailed report highlighting any
                    AI and/or plagiarism presence.
                </Typography>
            </CardContent>
        </Card>
    );
};

export default ReportCard;
