import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import ScanResults from '../../../components/Report/ScanResults/ScanResults';

import { Paper, CircularProgress, Box } from '@mui/material';
import { paperStyle, getUserReports } from '../../../utils/webUtils';
import { useStoreState } from 'pullstate';
import { AppStore } from '../../../stores/AppStore';

const SharedReport = () => {
    const [loading, setLoading] = useState(true);
    const [reportCreator, setReportCreator] = useState(false);
    const [reportData, setReportData] = useState(null);
    const { userDoc, reports } = useStoreState(AppStore);
    const { id } = useParams();

    useEffect(() => {
        const fetchReport = async () => {
            const docRef = doc(db, 'reports', id);
            try {
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    setReportData(docSnap.data());
                }
            } catch (error) {
                console.error('Error fetching document:', error);
            }
        };

        fetchReport();
    }, [id]);

    useEffect(() => {
        const fetchData = async () => {
            if (userDoc?.uid) {
                await getUserReports(userDoc.uid);
                const reportFound = reports.some((report) => report.reportId === id);
                if (reportFound) setReportCreator(true);
                setLoading(false);
            } else {
                setLoading(false);
            }
        };

        fetchData();
    }, [userDoc, id, reports]);

    if (!reportData || loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    const fullScanData = reportData.fullScanData;

    if (!fullScanData) {
        return <div>Report data is not available or incomplete.</div>;
    }

    return (
        <Paper sx={paperStyle}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <ScanResults
                    documentText={reportData.characters}
                    reportData={fullScanData}
                    reportId={id}
                    reportCreator={reportCreator}
                />
            </Box>
        </Paper>
    );
};

export default SharedReport;
