import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const ReportCard = () => {
    return (
        <Card sx={{ boxShadow: 'none', width: '100%', mb: 3 }}>
            <CardContent>
                <Typography variant='h4' gutterBottom>
                    Report Overview
                </Typography>
                <Typography variant='body1'>
                    You can download or share this report with a click and access your reports anytime. Dive in,
                    explore, and let's make learning and teaching even more impactful.
                </Typography>
            </CardContent>
        </Card>
    );
};

export default ReportCard;
