import React, { forwardRef, useImperativeHandle } from 'react';
import { format } from 'date-fns';
import { pdf, PDFViewer } from '@react-pdf/renderer';
import ReportPdfDocument from './ReportPdfDocument';
import { CurrentScan } from '../../../stores/CurrentScan';
import { useStoreState } from 'pullstate';

const ReportResults = forwardRef(({ reportDataProp, verificationLink }, ref) => {
    const { report } = useStoreState(CurrentScan);
    const reportData = reportDataProp || report?.fullScanData;

    if (!reportData || !reportData.scan_results) {
        console.error('Invalid report data:', reportData);
    }

    const downloadAsPdf = () => {
        const hasAICheck = aiFakeScore !== undefined || aiRealScore !== undefined; // Assuming these variables indicate AI checks
        const hasPlagiarismCheck = plagiarismScore !== undefined; // Assuming this variable indicates a plagiarism check

        let reportType = '';
        if (hasAICheck && hasPlagiarismCheck) {
            reportType = 'AI_Plagiarism_Report';
        } else if (hasAICheck) {
            reportType = 'AI_Detection_Report';
        } else if (hasPlagiarismCheck) {
            reportType = 'Plagiarism_Report';
        }

        const blob = pdf(
            <ReportPdfDocument
                reportData={reportData}
                formattedDataAndTime={formattedDataAndTime}
                aiFakeScore={aiFakeScore}
                aiFakeScoreExplanation={aiFakeScoreExplanation}
                aiRealScore={aiRealScore}
                aiRealScoreExplanation={aiRealScoreExplanation}
                plagiarismScore={plagiarismScore}
                plagiarismExplanation={plagiarismExplanation}
                scanType={reportData.scan_results.scan.scan_type}
                verificationLink={verificationLink}
            />
        ).toBlob();
        blob.then((pdfBlob) => {
            const url = URL.createObjectURL(pdfBlob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${reportType}.pdf`;
            document.body.appendChild(link);
            link.click();
            link.remove();
        });
    };

    useImperativeHandle(ref, () => ({
        downloadAsPdf,
    }));

    if (!reportData || !reportData.scan_results) {
        console.error('Invalid report data:', reportData);
        return <div>Invalid report data.</div>;
    }

    const formattedDataAndTime = format(
        new Date(reportData.scan_results.scan.created_at),
        'MMMM d, yyyy HH:mm:ss'
    );
    //const formattedDate = format(new Date(reportData.scan_results.scan.created_at), 'MMMM d, yyyy');
    const aiFakeScore = reportData.scan_results.aiResults.fake;
    const aiRealScore = reportData.scan_results.aiResults.real;

    // AI Fake Score Explanation
    let aiFakeScoreExplanation = '';
    if (aiFakeScore > 0.8) {
        aiFakeScoreExplanation =
            'High likelihood of AI-generated content. The writing patterns and stylistic elements strongly suggest AI involvement.';
    } else if (aiFakeScore > 0.3) {
        aiFakeScoreExplanation =
            'Moderate likelihood of AI-generated content. There are indications of AI usage, but human contribution is also evident.';
    } else {
        aiFakeScoreExplanation =
            'Low likelihood of AI-generated content. The content appears to be primarily human-generated with minimal AI influence.';
    }

    // AI Real Score Explanation
    let aiRealScoreExplanation = '';
    if (aiRealScore > 0.8) {
        aiRealScoreExplanation =
            'High confidence in original, human-created content. The text exhibits natural flow and complexity typical of human writing.';
    } else if (aiRealScore > 0.3) {
        aiRealScoreExplanation =
            'Moderate confidence in human-created content. While there are elements of human writing style, some sections may have AI influence.';
    } else {
        aiRealScoreExplanation =
            'Low confidence in original content. The text lacks the nuanced expression and complexity typically associated with human writing.';
    }

    // Plagiarism Score
    const plagiarismScore = reportData.scan_results.plagResults.documentScore;
    let plagiarismExplanation = '';
    if (plagiarismScore > 80) {
        plagiarismExplanation =
            'High likelihood of plagiarized content. Significant portions of the text match other sources.';
    } else if (plagiarismScore > 30) {
        plagiarismExplanation =
            'Moderate likelihood of plagiarism. Some portions of the text may be copied from other sources.';
    } else {
        plagiarismExplanation =
            'Low likelihood of plagiarism. The text appears to be mostly original.';
    }

    return (
        <PDFViewer width='100%' height='500px'>
            <ReportPdfDocument
                reportData={reportData}
                formattedDataAndTime={formattedDataAndTime}
                aiFakeScore={aiFakeScore}
                aiFakeScoreExplanation={aiFakeScoreExplanation}
                aiRealScore={aiRealScore}
                aiRealScoreExplanation={aiRealScoreExplanation}
                plagiarismScore={plagiarismScore}
                plagiarismExplanation={plagiarismExplanation}
                scanType={reportData.scan_results.scan.scan_type}
                verificationLink={verificationLink}
            />
        </PDFViewer>
    );
});

export default ReportResults;
