import { auth, signInWithGooglePopup } from '../../firebase';
import Logo from '../../assets/images/nobg-shield-name.png';
import '../../App.css';
import './Login.scss';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Alert,
    Box,
    Button,
    Divider,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
} from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    sendSignInLinkToEmail,
} from 'firebase/auth';
import { LoadingButton } from '@mui/lab';
const Login = (user) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [usePassword, setUsePassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [error, setError] = useState(false);
    const [resetNotice, setResetNotice] = useState(null);
    const [emailSent, setEmailSent] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const inputStyle = {
        width: '100%',
        background: '#fff',
        borderRadius: '5px',
        marginBottom: '1rem',
    };
    const loginGoogleUser = async () => {
        // GTM
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'sign_in_google',
        });
        await signInWithGooglePopup();
        // console.log(response);

        // GTM
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'sign_in_success',
        });
    };

    useEffect(() => {
        if (user.user) {
            // console.log('user', user);
            navigate('/dashboard');
        }
    }, [navigate, user]);

    const handleChange = (e, type) => {
        if (type === 'email') {
            setEmail(e.target.value);
        } else {
            setPassword(e.target.value);
        }
        // console.log(email, password);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleLoginOrCreate = async () => {
        setLoading(true);
        if (usePassword) {
            try {
                await signInWithEmailAndPassword(auth, email, password);
                // User logged in successfully.
                // ... (Redirect or do something else)
                setLoading(false);
            } catch (error) {
                if (error.code === 'auth/user-not-found') {
                    // No user exists, show confirm password field.
                    setLoading(false);
                    setShowConfirmPassword(true);
                } else {
                    // Some other error.
                    console.error(error.message);
                    setError(true);
                    setLoading(false);
                }
            }
        } else {
            try {
                await sendSignInLinkToEmail(auth, email, {
                    url: 'https://app.passed.ai/?institutional=true',
                    handleCodeInApp: true,
                }).then(() => {
                    // The link was successfully sent. Inform the user.
                    // Save the email locally so you don't need to ask the user for it again
                    // if they open the link on the same device.
                    window.localStorage.setItem('emailForSignIn', email);
                    setEmailSent(true);
                    setLoading(false);
                });
            } catch (error) {
                const errorCode = error.code;
                const errorMessage = error.message;
                setError(true);
                console.log(errorCode, errorMessage);
                setLoading(false);
            }
        }
    };

    const handleCreateUser = async () => {
        try {
            await createUserWithEmailAndPassword(auth, email, password);
            // User created and logged in successfully.
            // ... (Redirect or do something else)
        } catch (error) {
            console.error(error.message);
        }
    };

    const handlePasswordReset = async () => {
        try {
            await sendPasswordResetEmail(auth, email);
            // Password reset email sent!
            setResetNotice(`Password reset email sent to ${email}`);
            setEmail('');
            setPassword('');
            setError(false);
        } catch (error) {
            console.error(error.message);
        }
    };
    return (
        //create github login button
        <>
            <Box className='logo-container'>
                <img src={Logo} alt='Passed.AI' className='login-logo' />
                <Divider sx={{ height: 32 }} />
                <Button
                    startIcon={<GoogleIcon />}
                    variant='contained'
                    className='login_button'
                    onClick={loginGoogleUser}
                >
                    Register/login with Google
                </Button>
                <Divider className='divider' sx={{ margin: '2rem auto', color: '#1f2641' }}>
                    or register/login with email instead
                </Divider>
                {!emailSent && (
                    <>
                        <TextField
                            id='emailInput'
                            label='Email'
                            variant='outlined'
                            sx={inputStyle}
                            type='email'
                            placeholder='Email'
                            value={email}
                            onChange={(e) => handleChange(e, 'email')}
                        />
                        {usePassword && (
                            <>
                                <FormControl sx={{ m: 1, width: '100%' }} variant='outlined'>
                                    <InputLabel htmlFor='outlined-adornment-password'>
                                        Password
                                    </InputLabel>
                                    <OutlinedInput
                                        value={password}
                                        id='outlined-adornment-password'
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    aria-label='toggle password visibility'
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge='end'
                                                >
                                                    {showPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label='Password'
                                        sx={inputStyle}
                                        onChange={(e) => handleChange(e, 'password')}
                                    />
                                </FormControl>
                                {showConfirmPassword && (
                                    <FormControl sx={{ m: 1, width: '100%' }} variant='outlined'>
                                        <InputLabel htmlFor='outlined-adornment-confirm-password'>
                                            Confirm Password
                                        </InputLabel>
                                        <OutlinedInput
                                            id='outlined-adornment-confirm-password'
                                            type={showPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position='end'>
                                                    <IconButton
                                                        aria-label='toggle password visibility'
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge='end'
                                                    >
                                                        {showPassword ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label='Confirm Password'
                                            sx={inputStyle}
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                        />
                                    </FormControl>
                                )}
                            </>
                        )}
                        <LoadingButton
                            loading={loading}
                            variant='contained'
                            className='login_button primary'
                            onClick={showConfirmPassword ? handleCreateUser : handleLoginOrCreate}
                            disabled={
                                (!email && !usePassword) ||
                                (usePassword && !password) ||
                                (showConfirmPassword && password !== confirmPassword)
                            }
                            color='secondary'
                        >
                            {showConfirmPassword ? 'Sign up' : 'Send my login link'}
                        </LoadingButton>
                        <Divider sx={{ height: 32 }} />
                        <Button
                            variant='text'
                            className='reset_password'
                            onClick={() => setUsePassword((use) => !use)}
                        >
                            {usePassword ? 'Use login link instead' : 'Use password instead'}
                        </Button>
                    </>
                )}
                {emailSent && (
                    <>
                        <Alert severity='success'>
                            A login link has been sent to {email}. Please check your email and click
                            the link to login.
                        </Alert>
                        <Divider sx={{ height: 32 }} />
                        <Button
                            variant='text'
                            className='reset_password'
                            onClick={() => setEmailSent(false)}
                        >
                            Resend login link
                        </Button>
                    </>
                )}
                {error && (
                    <>
                        <Divider sx={{ height: 32 }} />
                        <Alert severity='error'>
                            There was an error logging you in. Please check your email and/or
                            password, then try again.
                        </Alert>
                        <Divider sx={{ height: 32 }} />
                        <Button
                            variant='text'
                            className='reset_password'
                            onClick={handlePasswordReset}
                        >
                            Reset password
                        </Button>
                    </>
                )}
                {resetNotice && (
                    <>
                        <Divider sx={{ height: 32 }} />
                        <Alert severity='success'>{resetNotice}</Alert>
                    </>
                )}
            </Box>
        </>
    );
};

export default Login;
