import React from 'react';
import { Box, Chip } from '@mui/material';

function ScanStats({ wordCount, reportsLeft }) {
    return (
        <Box
            className='scanOptionsStats'
            style={{ justifyContent: 'flex-end', alignSelf: 'center' }}
        >
            <Box style={{ display: 'flex' }}>
                <Chip label={'Words: ' + wordCount} color='primary' variant='outlined' />
                <Chip
                    label={'Reports left: ' + reportsLeft}
                    variant='contained'
                    sx={{ marginLeft: '1rem', backgroundColor: '#30BCED' }}
                />
            </Box>
        </Box>
    );
}

export default ScanStats;
