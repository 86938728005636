import { useEffect, useRef, useState } from 'react';
import { useStoreState } from 'pullstate';
import {
    Box,
    TextField,
    Typography,
    Backdrop,
    CircularProgress,
    Alert,
    Button,
} from '@mui/material';
import { CurrentScan } from '../../../stores/CurrentScan';
import { wordCounter, splitSentences } from '../../../utils/webUtils';
import './ScanInput.scss';

const ScanInput = () => {
    const [loadingMessage, setLoadingMessage] = useState('');
    const textareaRef = useRef(null);
    const currentIndex = useRef(0);
    const { scanDisabled, inputDisabled, content, wordCount, loading } = useStoreState(CurrentScan);
    const sentences = splitSentences(content);

    const handleInput = async (e) => {
        CurrentScan.update((s) => {
            s.content = e.target.value;
            s.words = e.target.value.split(' ');
            s.wordCount = wordCounter(e);
        });
    };

    useEffect(() => {
        const standardLoadingMessages = [
            'Checking sentence structure...',
            'Scanning for AI content...',
            'Finalizing report details...',
            'Almost there...',
        ];
        if (loading) {
            let contentMessageCount = 0; // Counter for the number of content sentences displayed

            const updateLoadingMessage = () => {
                if (currentIndex.current < 2) {
                    // Display the first two standard messages
                    setLoadingMessage(standardLoadingMessages[currentIndex.current]);
                } else if (contentMessageCount < 5) {
                    const sentenceIndex = (currentIndex.current - 2) * 10;
                    if (sentenceIndex < sentences.length) {
                        setLoadingMessage(
                            `Analyzing "${sentences[sentenceIndex].replace(/^\.\s/, '')}"...`
                        );

                        contentMessageCount++;
                    } else {
                        // If there are no more sentences, ensure we move to the last two standard messages
                        contentMessageCount = 5; // Ensure transition to final messages
                        currentIndex.current = 2 + 5; // Adjust currentIndex to skip to the final messages
                    }
                } else {
                    const finalMessageIndex = 2 + 5 + (currentIndex.current - (2 + 5));
                    if (finalMessageIndex < standardLoadingMessages.length) {
                        setLoadingMessage(standardLoadingMessages[finalMessageIndex]);
                    } else {
                        // Keep displaying the last standard message indefinitely
                        setLoadingMessage(
                            standardLoadingMessages[standardLoadingMessages.length - 1]
                        );
                    }
                }
            };

            // Set the initial message immediately
            updateLoadingMessage();

            const intervalId = setInterval(() => {
                currentIndex.current += 1; // Increment currentIndex for the next message
                updateLoadingMessage();
            }, 5000);

            return () => clearInterval(intervalId);
        }
    }, [loading, sentences]);

    return (
        <Box>
            <Box sx={{ position: 'relative' }}>
                <TextField
                    multiline
                    ref={textareaRef}
                    disabled={scanDisabled || inputDisabled || loading}
                    aria-label='empty textarea'
                    placeholder='Enter text here...'
                    className='contentBox'
                    debounce={1000}
                    minRows={20}
                    value={content}
                    onChange={handleInput}
                    maxRows={20}
                    resize='none'
                    sx={{ width: '100%' }}
                />
                <Backdrop
                    sx={{
                        color: 'white',
                        zIndex: (theme) => theme.zIndex.modal + 1,
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'center',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        borderRadius: '4px',
                        fontSize: '3rem',
                    }}
                    open={loading}
                >
                    <CircularProgress color='inherit' />
                    <Typography
                        sx={{
                            mt: 2,
                            color: 'white',
                            fontWeight: 'bolder',
                            fontSize: '1.5rem',
                            width: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        {loadingMessage}
                    </Typography>
                </Backdrop>
            </Box>
            <Typography variant='body2' sx={{ mt: '1rem' }}>
                * At least 50 words are required to perform the scan.
            </Typography>
            <Alert
                severity='success'
                sx={{ boxShadow: '1px 1px 2px grey', margin: '1rem 0' }}
                action={
                    <Button
                        href='https://passed.ai/multilanguage-ai-detection/'
                        target='_blank'
                        color='inherit'
                        size='small'
                    >
                        Learn More
                    </Button>
                }
            >
                Our AI scanner now supports 15 languages.
            </Alert>
            {wordCount < 50 && (
                <Alert
                    severity='info'
                    sx={{ backgroundColor: 'white', boxShadow: '1px 1px 2px grey' }}
                >
                    We require a minimum of 50 words to scan.
                </Alert>
            )}
        </Box>
    );
};

export default ScanInput;
