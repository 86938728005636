import React from 'react';
import BuyReports from './BuyReportsOptions';
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';

import TaskAltIcon from '@mui/icons-material/TaskAlt';

const Credits = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginBottom: '2.5rem',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginBottom: 2,
                }}
            >
                <Typography variant='h4'>Choose What's Best For You</Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <TaskAltIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography variant='body1' style={{ fontSize: '1.5rem' }}>
                                    Highly accurate AI and plagiarism detection
                                </Typography>
                            }
                        />
                    </ListItem>

                    <ListItem>
                        <ListItemIcon>
                            <TaskAltIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography variant='body1' style={{ fontSize: '1.5rem' }}>
                                    Download and share official report{' '}
                                </Typography>
                            }
                        />
                    </ListItem>
                </List>
            </Box>
            <BuyReports />
        </Box>
    );
};

export default Credits;
