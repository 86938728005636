import { React } from 'react';
import './MainReport.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useStoreState } from 'pullstate';
import { AppStore } from '../../stores/AppStore';

function MainPage({ content }) {
    const { userDoc } = useStoreState(AppStore);
    const mobile = useMediaQuery('(max-width:650px)');

    return (
        <div
            className={mobile ? 'main_page_mobile' : 'main_page_desktop'}
            style={{
                maxWidth: '100vw',
            }}
        >
            {content}
        </div>
    );
}

export default MainPage;
