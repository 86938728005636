import { FormControlLabel, FormGroup, Switch, Grid } from '@mui/material';
import { useStoreState } from 'pullstate';
import { CurrentScan } from '../../../stores/CurrentScan';
import { AppStore } from '../../../stores/AppStore';

import ScanButton from './ScanButton';
import './ScanOptions.scss';
import ScanStats from './ScanStats';

const ScanOptions = () => {
    const { aiCheck, plagiarismCheck, scanDisabled, wordCount, results } =
        useStoreState(CurrentScan);
    const { userDoc } = useStoreState(AppStore);

    const handleSwitch = (switchName) => {
        // console.log(switchName);
        CurrentScan.update((s) => {
            switch (switchName) {
                case 'aiCheck':
                    s.aiCheck = !s.aiCheck;
                    break;
                case 'plagiarismCheck':
                    s.plagiarismCheck = !s.plagiarismCheck;
                    break;
                default:
                    break;
            }
        });
    };

    return (
        <Grid
            container
            className='ScanOptions'
            sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                marginTop: '1rem',
                marginBottom: '1rem',
            }}
        >
            <Grid
                item
                xs={12}
                md={6}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: '1rem',
                }}
            >
                {!results && (
                    <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    disabled={scanDisabled}
                                    checked={aiCheck || false}
                                    onChange={() => handleSwitch('aiCheck')}
                                    color={aiCheck ? 'success' : 'primary'}
                                />
                            }
                            label='AI Content'
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    disabled={scanDisabled}
                                    checked={plagiarismCheck || false}
                                    onChange={() => handleSwitch('plagiarismCheck')}
                                    color={plagiarismCheck ? 'success' : 'primary'}
                                />
                            }
                            label='Plagiarized Content'
                        />
                    </FormGroup>
                )}
            </Grid>

            <Grid
                item
                xs={12}
                md={6}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    gap: '1rem',
                }}
            >
                <ScanStats wordCount={wordCount} reportsLeft={userDoc?.reports || 0} />
                <ScanButton />
            </Grid>
        </Grid>
    );
};

export default ScanOptions;
