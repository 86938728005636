import { GppGoodOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Tooltip, Fade } from '@mui/material';
import { useStoreState } from 'pullstate';
import { CurrentScan } from '../../../stores/CurrentScan';
import { splitSentences } from '../../../utils/webUtils';
import { AppStore } from '../../../stores/AppStore';

import { ReportsStore } from '../../../stores/ReportsStore';

const ScanButton = ({ children }) => {
    const { userDoc } = useStoreState(AppStore);
    const { currentStep } = useStoreState(ReportsStore);
    const {
        aiCheck,
        plagiarismCheck,
        content,
        wordCount,
        reportCost,
        creditCost,
        title,
        id,
        loading,
    } = useStoreState(CurrentScan);
    const scanType = aiCheck && plagiarismCheck ? 0 : plagiarismCheck ? 2 : 1;

    const getReport = () => {
        const scanId = id || 'app-scan-' + title + '-' + Date.now();
        CurrentScan.update((s) => {
            s.scanDisabled = true;
            s.error = false;
            s.scanId = scanId;
            s.loading = true;
        });
        const sentences = splitSentences(content);
        const scanTitle = title ? title : content.substring(0, 50);

        fetch(`https://us-central1-passedai.cloudfunctions.net/getReport`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                sentences: sentences,
                originalContent: content,
                scanType: scanType,
                reportCost: reportCost,
                creditCost: creditCost,
                title: scanTitle,
                user: userDoc.uid,
                id: scanId,
            }),
        })
            .then((response) => {
                // console.log('originality response', response);
                if (response.status === 200) {
                    AppStore.update((s) => {
                        s.userDoc.reports = s.userDoc.reports - reportCost;
                    });
                    console.log(response);
                    return response.json();
                } else {
                    console.error('error getting score', response);
                    CurrentScan.update((s) => {
                        s.error = true;
                        s.errorMessage =
                            'There was an error running your scan. Please try again and contact support if the problem persists.';
                        s.errorCTA = 'Contact Support';
                        s.scanDisabled = false;
                        s.loading = false;
                    });
                }
            })
            .then((data) => {
                // console.log('originality data', data?.result);
                if (data?.result?.code === 'ERR_BAD_REQUEST') {
                    CurrentScan.update((s) => {
                        s.error = true;
                        s.errorMessage =
                            'There was an error running your scan. Please try again and contact support if the problem persists.';
                        s.errorCTA = 'Contact Support';
                        s.scanDisabled = false;
                        s.loading = false;
                    });
                    return;
                } else {
                    AppStore.update((s) => {
                        s.userDoc.reports = s.userDoc.reports - reportCost;
                    });

                    CurrentScan.update((s) => {
                        s.scanDisabled = false;
                        s.loading = false;
                        s.report = data.report;
                    });

                    ReportsStore.update((s) => {
                        s.currentStep = currentStep + 3;
                        s.reports = [...s.reports, data.id];
                    });
                }
            })
            .catch((err) => {
                console.log('error', err);
                CurrentScan.update((s) => {
                    s.error = true;
                    s.scanDisabled = false;
                    s.loading = false;
                });
            });
    };

    const onClick = () => {
        if (!userDoc) {
            ReportsStore.update((s) => {
                s.currentStep = 2;
            });
        } else if (!userDoc.reports || userDoc.reports < reportCost) {
            ReportsStore.update((s) => {
                s.currentStep = 3;
            });
        } else {
            getReport();
        }
    };

    const buttonLabel = loading ? 'Generating report...' : 'Get report';

    const isDisabled =
        wordCount < 50 ||
        (!aiCheck && !plagiarismCheck) ||
        !userDoc.reports ||
        userDoc.reports < reportCost ||
        !userDoc;
    const tooltipTitle =
        wordCount < 50
            ? 'Word count must be at least 50 to generate a report.'
            : 'Please select if you want to AI content, plagiarized content, or both.';

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            {children}
            <Tooltip
                title={tooltipTitle}
                disableHoverListener={!isDisabled}
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                PopperProps={{
                    sx: {
                        '& .MuiTooltip-tooltip': {
                            fontSize: '1rem',
                        },
                    },
                }}
            >
                <span>
                    <LoadingButton
                        disabled={isDisabled}
                        size='large'
                        loading={loading}
                        loadingPosition='start'
                        startIcon={<GppGoodOutlined />}
                        variant='contained'
                        color='secondary'
                        onClick={onClick}
                    >
                        {buttonLabel}
                    </LoadingButton>
                </span>
            </Tooltip>
        </Box>
    );
};

export default ScanButton;
