import React, { useRef, useEffect, useState } from 'react';
import { Grid, Box, Button, TextField, Snackbar, Alert, Slide } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import ReportResult from '../ReportResults/ReportResults';
import ResultsCard from '../ResultsCard/ResultsCard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { resetCurrentScan } from '../../../stores/CurrentScan';

const ScanResults = ({ reportData, documentText, reportId, reportCreator }) => {
    const reportRef = useRef();
    const [isReportReady, setReportReady] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const verificationLink = `${window.location.origin}/report/${reportId}`;

    useEffect(() => {
        if (reportRef.current) {
            setReportReady(true);
        }
    }, []);

    function SlideTransition(props) {
        return <Slide {...props} direction='down' />;
    }

    const copyToClipboard = () => {
        const shareableLink = `${window.location.origin}/report/${reportId}`;
        if (navigator.clipboard && window.isSecureContext) {
            navigator.clipboard
                .writeText(shareableLink)
                .then(() => {
                    setOpenSnackbar(true);
                    setTimeout(() => setOpenSnackbar(false), 3000);
                })
                .catch((err) => console.error('Failed to copy link:', err));
        } else {
            console.error('Clipboard API not available or not in a secure context.');
        }
    };

    const handleDownload = () => {
        if (reportRef.current && isReportReady) {
            reportRef.current.downloadAsPdf();
        } else {
            console.error('Report is not ready for download');
        }
    };

    return (
        <Box sx={{ width: '90%' }}>
            <Grid container spacing={2} direction='column'>
                <Grid item xs={12}>
                    <ResultsCard />
                </Grid>
                <Grid item container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                marginBottom: 2,
                            }}
                        >
                            <Button
                                startIcon={<DownloadIcon />}
                                onClick={handleDownload}
                                variant='contained'
                                size='large'
                            >
                                Download
                            </Button>
                            <Button
                                startIcon={<ContentCopyIcon />}
                                onClick={copyToClipboard}
                                variant='contained'
                            >
                                Share
                            </Button>
                            {reportCreator && (
                                <Button
                                    startIcon={<AddIcon />}
                                    onClick={resetCurrentScan}
                                    variant='contained'
                                    size='large'
                                >
                                    New Scan
                                </Button>
                            )}
                        </Box>
                        <TextField
                            label='Document'
                            value={documentText}
                            multiline
                            rows={18}
                            InputProps={{ readOnly: true }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ReportResult
                            reportDataProp={reportData}
                            ref={reportRef}
                            verificationLink={verificationLink}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={5000}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                TransitionComponent={SlideTransition}
            >
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity='success'
                    sx={{ width: '100%' }}
                >
                    Report link copied to clipboard
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ScanResults;
