import {
    addDoc,
    collection,
    onSnapshot,
    query,
    doc,
    where,
    getDocs,
    deleteDoc,
} from 'firebase/firestore';
import { db } from '../firebase';
import { ReportsStore } from '../stores/ReportsStore';
import { AppStore } from '../stores/AppStore';
import { CurrentScan } from '../stores/CurrentScan';

export const getUserReports = async (userId) => {
    const userReportsRef = collection(db, 'users', userId, 'reports');
    const userReportsSnapshot = await getDocs(userReportsRef);

    const reportIds = userReportsSnapshot.docs.map((doc) => doc.data().reportId);

    const reports = [];
    for (const reportId of reportIds) {
        const reportRef = collection(db, 'reports');
        const q = query(reportRef, where('id', '==', reportId));
        const reportSnapshot = await getDocs(q);

        reportSnapshot.forEach((doc) => {
            const data = doc.data();
            reports.push({
                id: doc.id,
                aiFake: data.fullScanData.scan_results.aiResults?.fake,
                ...doc.data(),
            });
        });
    }

    AppStore.update((s) => {
        s.reports = reports;
    });
};

export const handleReportDelete = async (reportId, userDoc, reports) => {
    const userUid = userDoc.uid;

    const userReportRef = doc(db, 'users', userUid, 'reports', reportId);
    await deleteDoc(userReportRef);

    // Delete from the main reports collection
    const reportRef = doc(db, 'reports', reportId);
    await deleteDoc(reportRef);

    // Update local state to reflect the deletion
    const updatedReports = reports.filter((report) => report.id !== reportId);
    AppStore.update((s) => {
        s.reports = updatedReports;
    });
};

export const handleNextStep = (currentStep) => {
    ReportsStore.update((s) => {
        s.currentStep = currentStep + 1;
    });
};

export const handlePreviousStep = (currentStep, user) => {
    let steps = 1;
    if ((currentStep === 3 && user) || (currentStep === 5 && !CurrentScan.results)) {
        steps = 2;
    }
    ReportsStore.update((s) => {
        s.currentStep = currentStep - steps;
    });
};

export const calcReports = ({ wordCount }) => {
    return Math.ceil(wordCount / 5000);
};

export const calcCredits = ({ wordCount, aiCheck, plagiarismCheck }) => {
    if (aiCheck && plagiarismCheck) {
        return Math.ceil(wordCount / 100) * 2;
    } else {
        return Math.ceil(wordCount / 100);
    }
};

export const wordCounter = (e) => {
    return e.target.value.split(' ').filter(function (n) {
        return n !== '';
    }).length;
};

export const getWordCount = (text) => {
    return text
        .trim()
        .split(/\s+/)
        .filter((word) => word.length > 0).length;
};

export const gDocWordCount = (content) => {
    return content.split(' ').filter(function (n) {
        return n !== '';
    }).length;
};

export function splitSentences(content) {
    if (content) {
        var result = content.split('\n');

        // foreach result remove any empty strings
        // and any strings with less than 10 words
        // TODO , flaw with this is it ditches ellipsis ... and replaces with .
        result = result
            .map((r) => {
                return r.split(/(?=[.;!?])|(?:[.;!?])/g);
            })
            .flat();

        let temp = result;
        temp.forEach((sentence, index) => {
            if (['.', ';', '!', '?'].includes(sentence)) {
                // add the single character sentence to the previous actual sentence.
                result[index - 1] = result[index - 1] + sentence;
            }
        });
        // remove any extra punctuation, empty strings
        //  any strings with less than 10 words
        result = result.filter(function (el) {
            return el.length !== 0 && el.length !== 1 && el.split(' ').length > 10;
        });

        return result;
    } else {
        return [];
    }
}

export const stripePaymentLink = async (priceId, user, setLoading, windowLocationHref) => {
    setLoading(true);
    // const priceIds = [
    //     { price: 'price_1OZdWWGJ1Yvc8jVWYCkTOi6G', quantity: 1 },
    //     { price: 'price_1OZdWsGJ1Yvc8jVWhPhi5RGY', quantity: 1 },
    //     { price: 'price_1OZdX9GJ1Yvc8jVW29DR4JSP', quantity: 1 },
    // ];
    const purchase = async () => {
        const docRef = await addDoc(collection(db, 'users', user.uid, 'checkout_sessions'), {
            // line_items: [
            //     {
            //         price: priceId,
            //         quantity: 1,
            //     },
            // ],
            line_items: [{ price: priceId, quantity: 1 }],
            payment_intent_data: {
                metadata: {
                    user_id: user.uid,
                },
            },
            mode: 'payment',
            success_url: windowLocationHref + '/?success=reports',
            cancel_url: windowLocationHref + '/?cancelled=true',
            allow_promotion_codes: true,
            collect_shipping_address: false,
        });
        // Wait for the CheckoutSession to get attached by the extension
        onSnapshot(docRef, (snap) => {
            // console.log('snap', snap.data());
            const { error, url } = snap.data();
            // console.log('error', error);
            // console.log('url', url);
            if (error) {
                console.log(`An error occurred: ${error.message}`);
            }
            if (url) {
                // We have a Stripe Checkout URL, let's redirect.
                window.location.assign(url);
                setLoading(false);
            }
        });
    };

    purchase();
};

export const scanOptions = [
    {
        title: 'Basic',
        incl: '1 Report',
        type: 'Basic',
        reports: 1,
        price: 1.49,
        subtitle: "Ideal for first-time users exploring Passed.AI's capabilities.",
        productId: 'prod_POQNi5ENVyzUCn',
        priceId: 'price_1OZdWWGJ1Yvc8jVWYCkTOi6G',
    },
    {
        title: 'Premium',
        incl: '5 Reports',
        type: 'Most Popular',
        reports: 5,
        price: 6.49,
        subtitle: 'Designed for frequent users, offering more reports at a better value.',
        productId: 'prod_POQNuiGFJUmNbY',
        priceId: 'price_1OZdWsGJ1Yvc8jVWhPhi5RGY',
    },
    {
        title: 'Pro',
        incl: '10 Reports',
        type: 'Best Deal',
        reports: 10,
        price: 11.99,
        subtitle:
            'The ultimate package for extensive use, providing the most reports at the best price.',
        productId: 'prod_POQNFcJSPU1ibs',
        priceId: 'price_1OZdX9GJ1Yvc8jVW29DR4JSP',
    },
];

export const paperStyle = {
    padding: 2,
    backgroundColor: 'white',
    margin: '4rem 2rem',
    width: '95vw',
    overflowY: 'scroll',
    height: 'calc(100vh - 16rem)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
};
