import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import ReportCard from '../../components/Report/ReportCard/ReportCard';
import { useStoreState } from 'pullstate';
import { ReportsStore } from '../../stores/ReportsStore';
import { CurrentScan } from '../../stores/CurrentScan';
import Credits from '../../components/Report/BuyReports/BuyReportsHeader.jsx';
import ScanResults from '../../components/Report/ScanResults/ScanResults';
import { getWordCount, paperStyle as basePaperStyle } from '../../utils/webUtils';
import MyReports from '../../components/Report/MyReports/MyReports';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Scanner from '../../components/Report/Scanner/Scanner';

const Report = () => {
    const { currentStep } = useStoreState(ReportsStore);
    const { content, report } = useStoreState(CurrentScan);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const paperStyle = {
        ...basePaperStyle,
        padding: isMobile ? 1 : basePaperStyle.padding,
        margin: isMobile ? '1rem 0.5rem' : basePaperStyle.margin,
        height: isMobile ? 'calc(100vh - 8rem)' : basePaperStyle.height,
    };

    useEffect(() => {
        CurrentScan.update((s) => {
            s.wordCount = getWordCount(content);
        });
    }, [content]);

    const renderStepComponent = () => {
        switch (currentStep) {
            case 1:
                return (
                    <Box sx={paperStyle}>
                        <Box
                            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                        >
                            <ReportCard />

                            {!report.id && <Scanner />}
                        </Box>
                    </Box>
                );
            case 2:
                return (
                    <Box sx={paperStyle}>
                        <Box
                            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                        >
                            <ReportCard />

                            {!report.id && <Scanner />}
                        </Box>
                    </Box>
                );
            case 3:
                return (
                    <Box sx={paperStyle}>
                        <Credits />
                    </Box>
                );
            case 4:
                return (
                    //reportcreator logic: if they come from sharedReport, we check if they're the actual reportCreator. If not, they are already logged in and just scanned so we assume they are the reportCreator
                    <Box sx={paperStyle}>
                        <ScanResults
                            documentText={content}
                            reportId={report.id}
                            reportCreator={true}
                        />
                    </Box>
                );
            case 5:
                return (
                    <Box sx={paperStyle}>
                        <MyReports />
                    </Box>
                );
            default:
                return <Box>Error: Invalid Step</Box>;
        }
    };

    return <>{renderStepComponent()}</>;
};

export default Report;
