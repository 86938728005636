import {
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    setDoc,
    where,
    updateDoc,
    deleteDoc,
    addDoc,
    writeBatch,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage, ref, deleteObject } from 'firebase/storage';
import { db } from '../firebase';
import { AppStore } from '../stores/AppStore';

export const getSetUserDoc = async (user) => {
    try {
        const docRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(docRef);

        let userDoc;
        let data;
        if (docSnap.exists()) {
            data = docSnap.data();
            const id = docSnap.id;
            userDoc = { ...data, id };
            if (data?.institutionId) {
                const institutionRef = doc(db, 'institutions', data.institutionId);
                const institutionSnap = await getDoc(institutionRef);
                const institutionData = institutionSnap.data();
                userDoc.institutionInfo = institutionData;
                AppStore.update((s) => {
                    s.user = user;
                    s.userDoc = { ...s.userDoc, institutionInfo: institutionData, ready: true };
                });
            }
        } else {
            const userData = {
                name: user.displayName,
                email: user.email,
                photoUrl: user.photoURL,
                active: false,
                uid: user.uid,
            };

            await setDoc(docRef, userData);
            userDoc = userData;
        }
        AppStore.update((s) => {
            s.user = user;
            s.userDoc = { ...userDoc, ready: data.institutionId || data.ready ? true : false };
        });
    } catch (error) {
        // Handle error here
        console.error(error);
    }
};

export const getSubCollectionData = async (userId, subCollectionName, store) => {
    // console.log('getting subcollection data', userId, subCollectionName);
    const userRef = doc(db, 'users', userId);
    const subCollectionQuery = query(collection(userRef, subCollectionName));

    const querySnapshot = await getDocs(subCollectionQuery);

    const data = [];

    if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
            const docData = doc.data();
            const id = doc.id;
            data.push({ ...docData, id });
        });
    } else {
        // console.log(`No ${subCollectionName} found for this user.`);
    }
    // console.log('data', data);
    store.update((s) => {
        s[subCollectionName] = data;
    });

    return data;
};
export const addDocToSubCollection = async (userId, subCollectionName, data, store) => {
    const userRef = doc(db, 'users', userId);
    const subCollectionRef = collection(userRef, subCollectionName);

    // Check if a document with the same identifier already exists, but only for 'students' and 'courses' subcollections
    if (['courses', 'students'].includes(subCollectionName)) {
        const queryRef = query(subCollectionRef, where('identifier', '==', data.identifier));
        const querySnapshot = await getDocs(queryRef);

        if (!querySnapshot.empty) {
            throw new Error('A document with the same identifier already exists.');
        }
    }

    const docRef = await addDoc(subCollectionRef, data);

    store.update((s) => {
        const updatedData = [...s[subCollectionName], { ...data, id: docRef.id }];
        return { ...s, [subCollectionName]: updatedData };
    });

    return docRef.id;
};

export const editDocInSubCollection = async (userId, subCollectionName, docId, newData, store) => {
    const userRef = doc(db, 'users', userId);
    const docRef = doc(userRef, subCollectionName, docId);
    await updateDoc(docRef, newData);

    store.update((s) => {
        const updatedData = s[subCollectionName].map((item) =>
            item.id === docId ? { ...item, ...newData } : item
        );
        return { ...s, [subCollectionName]: updatedData };
    });

    if (subCollectionName === 'courses' || subCollectionName === 'students') {
        getSubCollectionData(userId, 'scans');
    }
};
export const deleteDocFromSubCollection = async (userId, subCollectionName, docId, store) => {
    const userRef = doc(db, 'users', userId);
    const docRef = doc(userRef, subCollectionName, docId);
    await deleteDoc(docRef);

    if (store) {
        store.update((s) => {
            const updatedData = s[subCollectionName].filter((item) => item.id !== docId);
            return { ...s, [subCollectionName]: updatedData };
        });
    }
};

export const shareAssignment = async (userId, subCollectionName, docId, store, students) => {
    // console.log('sharing assignment', userId, subCollectionName, docId, store, students);
    const docRef = doc(db, 'users', userId, subCollectionName, docId);
    // console.log('docRef', docRef);
    const studentsRef = collection(docRef, 'students');

    // Create a new batch using writeBatch instead of db.batch
    const batch = writeBatch(db);

    students.forEach((student) => {
        const studentRef = doc(studentsRef, student);
        batch.set(studentRef, { email: student });
    });

    try {
        await batch.commit();
        await updateDoc(docRef, { shared: true });
    } catch (error) {
        console.error('Error sharing assignment:', error);
        throw error;
    }

    if (store) {
        store.update((s) => {
            const updatedData = s[subCollectionName].filter((item) => item.id !== docId);
            return { ...s, [subCollectionName]: updatedData };
        });
    }
};
export const deleteFromFirestore = async (userId) => {
    const userDocRef = doc(db, 'users', userId);

    const deleteCollectionDocs = async (collectionPath) => {
        const collectionRef = collection(db, collectionPath);
        const docsSnapshot = await getDocs(collectionRef);
        docsSnapshot.forEach((doc) => deleteDoc(doc.ref));

        if (!docsSnapshot.empty) {
            docsSnapshot.forEach((doc) => deleteDoc(doc.ref));
        }
    };

    // List all the subcollections
    const collectionsToDelete = [
        'checkout_sessions',
        'marketingEmails',
        'payments',
        'subscriptions',
    ];

    for (const collectionName of collectionsToDelete) {
        await deleteCollectionDocs(`users/${userId}/${collectionName}`);
    }

    // Delete the main user document after all its subcollections have been deleted
    await deleteDoc(userDocRef);
};

export const deleteFromAuth = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
        try {
            await user.delete();
            console.log('User deleted from Firebase Auth');
        } catch (error) {
            console.error('Error deleting user from Firebase Auth:', error);
        }
    } else {
        console.log('No user currently logged in');
    }
};

export const deleteFromFireBase = async (userId) => {
    const storage = getStorage();

    const userRef = ref(storage, `users/${userId}`);
    await deleteObject(userRef);
};
